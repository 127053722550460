import { useEffect } from 'react'
import { AuthUtils } from '../../../utils/AuthUtils'
import _ from 'lodash'
import { checkVerifyToken } from '../../../repository/LoginRepository'

const Main = () => {
  useEffect(() => {
    const token = AuthUtils.getCookie('token');
    if(!_.isEmpty(token)){
      checkVerifyToken(token)
        .then((res) => {
          if (res.id > 0) {
            location.href = '/data/meta/sets';
          }
        })
        .catch((error) => console.error(error));
    }else{
      location.href = '/login';
    }
  }, [])


  return <></>;
}

export default Main