import React from 'react'
import './App.css'
import { BrowserRouter } from 'react-router-dom'
import Router from './components/router'
import { RecoilRoot } from 'recoil'

function App() {
  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <RecoilRoot>
        <Router />
      </RecoilRoot>
    </BrowserRouter>
  )
}

export default App
