import React, { useEffect, useMemo, useState } from 'react'
import {
  Cell,
  Column,
  ColumnInstance,
  HeaderGroup,
  Row,
  TableInstance,
  TableState,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import S from './RawData.module.scss'
import dayjs from 'dayjs'
import { Cafe24ContentsType } from '../../../../types/MetaDataTypes'
import { fetchRawData, uploadCafe24Excel } from '../../../../repository/MetaDataRepository'
import { UserInfoService } from '../../../../service/UserInfoService'
import { AuthorizationTokenAtom } from '../../../../recoil/Authorization.recoil'
import { useRecoilValue } from 'recoil'
import _ from 'lodash'


const columns: Column<Cafe24ContentsType>[] = [
  { Header: '광고매체', accessor: 'adMedia' },
  { Header: '날짜', accessor: 'date' },
  { Header: '방문수', accessor: 'visitCount' },
  { Header: '방문비율', accessor: 'visitRatio' },
  { Header: '구매건수', accessor: 'purchaseCount' },
  { Header: '구매율', accessor: 'purchaseRatio' },
  { Header: '매출액', accessor: 'revenue' },
  { Header: '구매당 매출', accessor: 'revenuePerPurchase' },
  { Header: '방문당 매출', accessor: 'revenuePerVisit' },
]

const RawData: React.FC = () => {
  UserInfoService()

  const token = useRecoilValue(AuthorizationTokenAtom)
  const [data, setData] = useState<Cafe24ContentsType[]>([])
  const [startDate, setStartDate] = useState<Date>(dayjs().subtract(1, 'day').toDate())
  const [endDate, setEndDate] = useState<Date>(dayjs().toDate())
  const [uploadDate, setUploadDate] = useState<Date>(dayjs().toDate())
  const [mediaCode, setMediaCode] = useState<string>('')
  const [currentPage, setCurrentPage] = useState(0)
  const [totalPages, setTotalPages] = useState(0)
  const [pageSize, setPageSize] = useState(30)
  const [totalElements, setTotalElements] = useState(0)
  const [isLast, setIsLast] = useState(false)
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  useEffect(() => {
    if (!_.isEmpty(token)) {
      fetchData();
    }
  }, [currentPage, pageSize])

  const fetchData = () => {
    fetchRawData(token, mediaCode, dayjs(startDate).format('YYYY-MM-DD'), dayjs(endDate).format('YYYY-MM-DD'), currentPage, pageSize)
      .then((res) => {
        setData(res.content)
        setCurrentPage(res.pageNumber)
        setPageSize(res.pageSize)
        setTotalElements(res.totalElements)
        setIsLast(res.isLast)
        setTotalPages(res.totalPages)
        setTablePageSize(pageSize);
      }).catch((e) => {
      console.error(e.message)
      alert('데이터 조회 실패!')
    })
  }

  const handlePageChange = (page: number) => {
    gotoPage(page)
    setCurrentPage(page)
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (file) {
      setSelectedFile(file);
    }
  }

  const handleFileUpload = () => {
    if (!selectedFile) {
      alert('Please select a file first!');
      return;
    }
    if(uploadDate == null){
      alert('데이터 날짜를 필수로 지정해야 합니다.');
      return;
    }

    uploadCafe24Excel(token, selectedFile, dayjs(uploadDate).format('YYYY-MM-DD'))
      .then((res) => {
        if (res.success) {
          alert('Success uploading data')
          fetchData()
        } else {
          alert('Error uploading data : ' + res.description)
        }
      })
      .catch((e) => {
        alert('Error uploading data')
      })
  }

  const handleSearch = () => {
    fetchData()
  }

  const tableInstance: TableInstance<Cafe24ContentsType> = useTable<Cafe24ContentsType>(
    {
      columns: useMemo(() => columns, []),
      data,
      initialState: { pageIndex: 0 } as Partial<TableState<Cafe24ContentsType>>,
    },
    useSortBy,
    usePagination,
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    gotoPage,
    setPageSize: setTablePageSize,
    state: { pageIndex, pageSize: tablePageSize },
  } = tableInstance

  return (
    <div className={S.container}>
      <h1>Cafe24 Data</h1>
      <div className={S.uploadSection}>
        <h2>엑셀 업로드</h2>
        <div className={S.uploadGroup}>
          <div className={S.uploadItem}>
            <label htmlFor="fileUpload">file</label>
            <input
              type="file"
              accept=".csv"
              onChange={handleFileChange}
              id="fileUpload"
            />
          </div>
          <div className={S.uploadDateItem}>
            <label htmlFor="uploadDate">데이터 날짜</label>
            <DatePicker
              selected={uploadDate}
              onChange={(date) => date && setUploadDate(date)}
              placeholderText="데이터 날짜"
              dateFormat={'yyyy-MM-dd'}
              className={S.customDatePicker}
            />
          </div>
          <div className={S.uploadButtonContainer}>
            <button onClick={handleFileUpload} className={S.uploadBtn}>
              Upload
            </button>
          </div>
        </div>
      </div>

      <div className={S.clearBoth}></div>
      <div className={S.searchContainer}>
        <h2>Search Filters</h2>
        <div className={S.searchGroup}>
          <div className={S.flexContainer}>
            <div className={S.datePicker}>
              <div className={S.datePickerItem}>
                <label htmlFor="from">From</label>
                <DatePicker
                  selected={startDate}
                  onChange={(date) => date && setStartDate(date)}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  placeholderText="From"
                  dateFormat={'yyyy-MM-dd'}
                  className={S.customDatePicker}
                />
              </div>
              <span className={S.datePickerSeparator}>~</span>
              <div className={S.datePickerItem}>
                <label htmlFor="to">To</label>
                <DatePicker
                  selected={endDate}
                  onChange={(date) => date && setEndDate(date)}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                  placeholderText="To"
                  dateFormat={'yyyy-MM-dd'}
                  className={S.customDatePicker}
                />
              </div>
            </div>
            <div className={S.metaName}>
              <label htmlFor="mediaCode">광고 매체 코드</label>
              <input
                className={S.searchInput}
                type="text"
                value={mediaCode}
                onChange={(e) => setMediaCode(e.target.value)}
                placeholder="광고 매체 코드"
              />
            </div>
            <div className={S.searchButtonContainer}>
              <button className={S.searchBtn} onClick={handleSearch}>
                검색
              </button>
            </div>
          </div>
        </div>
      </div>

      <p className={S.totalAdsLabel}>총 데이터 수: {totalElements}</p>
      <table {...getTableProps()} className={S.table}>
        <thead>
          {headerGroups.map((headerGroup: HeaderGroup<Cafe24ContentsType>) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(
                (column: ColumnInstance<Cafe24ContentsType>) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render('Header')}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? ' ↓'
                          : ' ↑'
                        : '↕'}
                    </span>
                  </th>
                ),
              )}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row: Row<Cafe24ContentsType>, i: number) => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell: Cell<Cafe24ContentsType>) => (
                  <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                ))}
              </tr>
            )
          })}
        </tbody>
      </table>
      <div className={S.pagination}>
        <button
          onClick={() => handlePageChange(0)}
          disabled={currentPage === 0}
        >
          {'<<'}
        </button>
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 0}
        >
          {'<'}
        </button>
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={isLast}
        >
          {'>'}
        </button>
        <button onClick={() => handlePageChange(totalPages)} disabled={isLast}>
          {'>>'}
        </button>
        <span>
          Page{' '}
          <strong>
            {currentPage + 1} of {totalPages}
          </strong>{' '}
        </span>
        <span>
          | Go to page:{' '}
          <input
            type="number"
            defaultValue={pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0
              handlePageChange(page)
            }}
            style={{ width: '100px' }}
          />
        </span>
        <select
          value={pageSize}
          onChange={(e) => {
            setTablePageSize(Number(e.target.value))
            setPageSize(Number(e.target.value))
          }}
        >
          {[10, 20, 30, 40, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>
    </div>
  )
}

export default RawData
