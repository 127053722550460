import React, { useState } from 'react';
import { useLocation, NavLink } from 'react-router-dom';
import S from './Sidebar.module.scss';

const Sidebar: React.FC = () => {
  const location = useLocation();
  const [isMetaHovered, setIsMetaHovered] = useState(false);

  const handleMetaMouseEnter = () => {
    setIsMetaHovered(true);
  };

  const handleMetaMouseLeave = () => {
    setIsMetaHovered(false);
  };

  const renderMenu = () => {
    switch (location.pathname.split('/')[1]) {
      case 'ad-settings':
        return (
          <>
            <NavLink to="/ad-settings/meta" className={({ isActive }) => isActive ? S.active : S.navItem}>META</NavLink>
          </>
        );
      case 'data':
        return (
          <>
            <NavLink to="/data/raw" className={({ isActive }) => isActive ? S.active : S.navItem}>광고데이터</NavLink>
            <div
              className={S.navItem}
              onMouseEnter={handleMetaMouseEnter}
              onMouseLeave={handleMetaMouseLeave}
            >
              <NavLink
                to="/data/meta/sets"
                className={({ isActive }) => location.pathname.split('/')[2] === 'meta' ? S.active : S.navItem}
              >
                META
              </NavLink>
              <div className={S.subMenu}>
                <NavLink to="/data/meta/sets" className={({ isActive }) => isActive ? S.subActive : S.subNavItem}>
                  ㄴ SETS
                </NavLink>
                <NavLink to="/data/meta/ad" className={({ isActive }) => isActive ? S.subActive : S.subNavItem}>
                  ㄴ ADㅤ
                </NavLink>
              </div>
            </div>
          </>
        );
      case 'my-info':
        return (
          <>
            <NavLink to="/my-info/account" className={({ isActive }) => isActive ? S.active : S.navItem}>광고 계정</NavLink>
          </>
        )
      default:
        return null
    }
  };

  return (
    <aside className={S.sidebar}>
      <nav className={S.nav} key={location.pathname}>
        {renderMenu()}
      </nav>
      <div className={S.separator}></div>
    </aside>
  );
};

export default Sidebar;
