import { RepositoryUtils } from '../utils/RepositoryUtils'
import {
  AD_STATUS,
  RawDataType,
  MetaAdType,
  MetaBudgetEditType,
  MetaSelectBoxResponseType,
  MetaStatusEditType,
  MetaAdDetailType,
  MetaSetsType,
  MetaSetsDetailType,
  AdHistoryResponseType, MemoUpdateType,
} from '../types/MetaDataTypes'
import { ApiPostResponseType, ApiPutResponseType } from '../types/ApiResponseType'
import { MetaAdAccountType } from '../types/LoginTypes'

const apiDomain = process.env.REACT_APP_API_DOMAIN

export const fetchMetaSetsData = (token: string, queryParams: string) => {
  return RepositoryUtils.axiosGet<MetaSetsType[]>(
    `${apiDomain}/api/v1/ads/meta/sets/insights/_search?${queryParams}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
}

export const fetchMetaAccounts = (token: string) => {
  return RepositoryUtils.axiosGet<MetaAdAccountType[]>(`${apiDomain}/api/v1/ads/meta/accounts`, {
    headers: {
      Authorization: `Bearer ${token}`,
    }
  })
}

export const fetchMetaAdsData = (token: string, queryParams: string) => {
  return RepositoryUtils.axiosGet<MetaAdType[]>(
    `${apiDomain}/api/v1/ads/meta/ads/insights/_search?${queryParams}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const fetchMetaAdDetails = (token: string, adId: string, from: string, to: string) => {
  return RepositoryUtils.axiosGet<MetaAdDetailType>(
    `${apiDomain}/api/v1/ads/meta/ads?adId=${adId}&from=${from}&to=${to}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
}

export const fetchMetaSetsDetails = (token: string, adId: string, from: string, to: string) => {
  return RepositoryUtils.axiosGet<MetaSetsDetailType>(
    `${apiDomain}/api/v1/ads/meta/sets?setId=${adId}&from=${from}&to=${to}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
}

export const changeAdStatus = (token: string, adId: string, status: AD_STATUS, memo: string | null = null) => {
  return RepositoryUtils.axiosPut<ApiPostResponseType>(
    `${apiDomain}/api/v1/ads/meta/ads/status`,
    {
      id: adId,
      status: status,
      memo: memo
    }, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
}

export const changeSetsStatus = (token: string, setId: string, status: AD_STATUS, memo: string | null) => {
  return RepositoryUtils.axiosPut<ApiPostResponseType>(
    `${apiDomain}/api/v1/ads/meta/sets/status`,
    {
      id: setId,
      status: status,
      memo: memo
    }, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
}

export const changeSetsDailyBudget = (token: string, setId: string, dailyBudget: number, memo: string | null) => {
  return RepositoryUtils.axiosPut<ApiPostResponseType>(
    `${apiDomain}/api/v1/ads/meta/sets/daily-budget`,
    {
      id: setId,
      dailyBudget: dailyBudget,
      memo: memo
    }, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
}

export const metaBulkChangeSetsDailyBudget = (token: string, requestPayload: MetaBudgetEditType[]) => {
  return RepositoryUtils.axiosPut<ApiPutResponseType[]>(
    `${apiDomain}/api/v1/ads/meta/sets/daily-budget/many`,
    requestPayload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
}

export const metaBulkChangeSetsStatus = (token: string, requestPayload: MetaStatusEditType[]) => {
  return RepositoryUtils.axiosPut<ApiPutResponseType[]>(
    `${apiDomain}/api/v1/ads/meta/sets/status/many`,
    requestPayload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
}

export const metaBulkChangeAdStatus = (token: string, requestPayload: MetaStatusEditType[]) => {
  return RepositoryUtils.axiosPut<ApiPutResponseType[]>(
    `${apiDomain}/api/v1/ads/meta/ads/status/many`,
    requestPayload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
}


export const fetchRawData = (token: string, adMedia: string, from: string, to: string, page: number, size: number) => {
  return RepositoryUtils.axiosGet<RawDataType>(
    `${apiDomain}/api/v1/ads/cafe24?adMedia=${adMedia}&from=${from}&to=${to}&page=${page}&size=${size}`,
    {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export const fetchAdHistories = (token: string, accountId: string, adId: string) => {
  return RepositoryUtils.axiosGet<AdHistoryResponseType>(
    `${apiDomain}/api/v1/ads/meta/histories/ad?metaAccountId=${accountId}&adId=${adId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )
}

export const fetchSetsHistories = (token: string, accountId: string, setId: string) => {
  return RepositoryUtils.axiosGet<AdHistoryResponseType>(
    `${apiDomain}/api/v1/ads/meta/histories/set?metaAccountId=${accountId}&setId=${setId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )
}

export const syncDataManually = (token: string, from: string, to: string) => {
  return RepositoryUtils.axiosPut<ApiPostResponseType>(
    `${apiDomain}/api/v1/ads/meta/sync?from=${from}&to=${to}`,{},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
}

export const updateMemo = (token: string, adId: string, memo: string) => {
  return RepositoryUtils.axiosPut<ApiPostResponseType>(
    `${apiDomain}/api/v1/ads/meta/ads/memo`,{
      id: adId,
      memo: memo,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
}

export const uploadCafe24Excel = (token: string, file: File, date: String) => {
  return RepositoryUtils.axiosPost<ApiPostResponseType>(`${apiDomain}/api/v1/ads/cafe24/upload?date=${date}`,
    {file: file}, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data',
    },
  })
}

