import { useEffect } from 'react'
import { AuthUtils } from '../../../utils/AuthUtils'
import { AuthorizationTokenAtom, LoginFailureFlagAtom } from '../../../recoil/Authorization.recoil'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { useNavigate, useSearchParams } from 'react-router-dom'
import _ from 'lodash'

const Auth = () => {
  const [queryParams] = useSearchParams();
  const navigate = useNavigate();
  const setToken = useSetRecoilState(AuthorizationTokenAtom)
  const EXPIRE_60_MINUTES = 3600;
  const [loginFailed, setLoginFailed] = useRecoilState(LoginFailureFlagAtom);

  const accessToken = queryParams.get('accessToken');
  const authenticated = queryParams.get('authenticated');

  useEffect(() => {
    if (accessToken !== null && authenticated === 'true') {
      const existedToken = AuthUtils.getCookie('token');
      if (accessToken !== existedToken) {
        if (!_.isEmpty(existedToken)) {
          AuthUtils.removeCookie('token'); // 새로운 토큰으로 들어오면 기존 토큰을 삭제한다
        }
        AuthUtils.setCookie('token', accessToken, {
          path: '/',
          secure: true,
          maxAge: EXPIRE_60_MINUTES,
        });
        setToken(accessToken);
        setLoginFailed(false);
        navigate('/data/meta/sets', { replace: true });
      }
    }else{
      setLoginFailed(true); // Set the flag to true on failure
      navigate('/login', { replace: true }); //TODO :: 에러 페이지 만들기
    }
  }, [queryParams]);

  return <></>;
}
export default Auth;