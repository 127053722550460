import React from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import S from './Header.module.scss';
import { AuthUtils } from '../../../utils/AuthUtils'
import { AuthorizationTokenAtom } from '../../../recoil/Authorization.recoil'
import { useRecoilState } from 'recoil'

const Header: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const isActivePath = (path: string) => location.pathname.includes(path);
  const [token, setToken] = useRecoilState(AuthorizationTokenAtom);

  const handleLogout = () => {
    AuthUtils.removeCookie('token');
    setToken('');
  };

  return (
    <header className={S.header}>
      <div className={S.logo} />
      <nav className={S.navbar}>
        {/*<NavLink to="/ad-settings/meta" className={({ isActive }) => isActivePath('/ad-settings') ? S.active : S.navItem} >광고 on/off 설정</NavLink>*/}
        <NavLink to="/data/meta/sets" className={({ isActive }) => isActivePath('/data') ? S.active : S.navItem}>데이터</NavLink>
        <NavLink to="/my-info/account" className={({ isActive }) => isActivePath('/my-info') ? S.active : S.navItem}>내
          정보</NavLink>
      </nav>
      <button className={S.logoutButton} onClick={handleLogout}>로그아웃</button>
      <div className={S.separator}></div>
    </header>
  );
};

export default Header;
