import {Cookies} from 'react-cookie';
import _ from 'lodash';
import { NavigateFunction } from 'react-router-dom'

const cookies = new Cookies();

export const AuthUtils = {
    setCookie: (name: string, value: string, options?: any) => {
        return cookies.set(name, value, {...options});
    },

    getCookie: (name: string) => {
        return cookies.get(name);
    },

    removeCookie: (name: string, options?: any) => {
        return cookies.remove(name, {path: '/', ...options, expires: new Date(0)});
    },

    needLogin: (navigate: NavigateFunction) => {
        navigate('/login', { replace: true });
    },

    isLoggedInAndRedirect: (token: string, redirectComponent: string) => {
        if (_.isEmpty(token)) {
            const tokenFromCookie = AuthUtils.getCookie('token');

            if (_.isEmpty(tokenFromCookie)) {
                if (_.isEmpty(redirectComponent)) {
                    location.href = `/login`;
                }
                location.href = `/${redirectComponent}`;
            }
        }
    },

    isLoggedIn: () => {
        const tokenFromCookie = AuthUtils.getCookie('token');

        if (_.isEmpty(tokenFromCookie)) {
            return false
        } else {
            return true;
        }
    },
};
