import React, { useEffect, useState } from 'react'
import styles from './Footer.module.scss';
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Terms from './Terms'
import Privacy from './Privacy'

const Footer: React.FC = () => {
  const [isTermsOpen, setIsTermsOpen] = useState(false);
  const [isPrivacyOpen, setIsPrivacyOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname === '/terms') {
      openTermsPopup();
    } else if (location.pathname === '/privacy') {
      openPrivacyPopup();
    }
  }, [location.pathname]);
  const openTermsPopup = () => {
    setIsTermsOpen(true);
  }
  const closeTermsPopup = () => {
    setIsTermsOpen(false);
    if (location.pathname === '/terms') {
      navigate('/'); // 팝업 닫을 때 '/'로 이동
    }
  }

  const openPrivacyPopup = () => {
    setIsPrivacyOpen(true);
  }
  const closePrivacyPopup = () => {
    setIsPrivacyOpen(false);
    if (location.pathname === '/privacy') {
      navigate('/'); // 팝업 닫을 때 '/'로 이동
    }
  }

  return (
    <footer className={styles.footer}>
      <div className={styles.footerLinks}>
        <button onClick={openTermsPopup} className={styles.footerButton}>
          이용약관
        </button>
        <button onClick={openPrivacyPopup} className={styles.footerButton}>
          개인정보 처리방침
        </button>
      </div>

      {/* 이용약관 팝업 */}
      {isTermsOpen && <Terms closePopup={closeTermsPopup} />}

      {/* 개인정보 처리방침 팝업 */}
      {isPrivacyOpen && <Privacy closePopup={closePrivacyPopup} />}

      <div className={styles.companyInfo}>
        <p>
          상호명: 주식회사 애드커넥션
          <br />
          대표자: 박우일
          <br />
          사업자등록번호: 721-87-02315
          <br />
          주소: 서울시 영등포구 선유로 3길 10 하우스디비즈 806호
          <br />
          이메일: ad@adconnection.co.kr
        </p>
      </div>
    </footer>
  );
};

export default Footer;
