import { RepositoryUtils } from '../utils/RepositoryUtils'
import { MyInfoType, VerifyLoginResponse, VerifyTokenType } from '../types/LoginTypes'
import { ApiPostResponseType } from '../types/ApiResponseType'

const apiDomain = process.env.REACT_APP_API_DOMAIN

export const checkVerifyToken = (token: string) => {
  return RepositoryUtils.axiosGet<MyInfoType>(
    `${apiDomain}/api/v1/auth/verify-token?accessToken=${token}`,
    {},
  )
}

export const fetchUsersMe = (token: string) => {
  return RepositoryUtils.axiosGet<MyInfoType>(`${apiDomain}/api/v1/me`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export const generalLogin = (id: string, password: string) => {
  return RepositoryUtils.axiosPost<VerifyLoginResponse>(
    `${apiDomain}/api/v1/auth/login${window.location.hostname === 'localhost' ? '?redirectUrl=http://localhost:3000' : ''}`,
    { id: id, password: password },
    {},
  )
}
