import axios, { AxiosError, AxiosRequestConfig } from 'axios'

export const RepositoryUtils = {
  axiosGet: async <T = any>(url: string, options?: AxiosRequestConfig) => {
    try {
      const response = await axios.get<T>(url, options)
      return response.data
    } catch (error) {
      return error as T
    }
  },
  axiosPost: async <T = any, E = any>(url: string, data: any, options?: AxiosRequestConfig) => {
    try {
      const response = await axios.post<T>(url, data, options)
      return response.data
    } catch (error) {
      if (error instanceof AxiosError) {
        // AxiosError 처리
        return error.response?.data as E // 오류 데이터를 E 타입으로 처리
      }
      // 기타 오류 타입 처리
      return error as unknown as E // E 타입으로 캐스팅
    }
  },
  axiosPut: async <T = any>(url: string, data: any, options?: AxiosRequestConfig) => {
    try {
      const response = await axios.put<T>(url, data, options)
      return response.data
    } catch (error: any) {
      if (error.response) {
        // Handle the error response from the server
        const { data, status } = error.response;
        return {
          status,
          ...data, // This will include the `code` and `message` from the server response
        } as T;
      } else {
        // Handle other errors (e.g., network issues)
        return error as T;
      }
    }
  },
  axiosPatch: async <T = any>(url: string, data: any, options?: AxiosRequestConfig) => {
    try {
      const response = await axios.patch<T>(url, data, options)
      return response.data
    } catch (error) {
      return error as T
    }
  },
  axiosDelete: async <T = any>(url: string, options?: AxiosRequestConfig) => {
    try {
      const response = await axios.delete<T>(url, options)
      return response.data
    } catch (error) {
      return error as T
    }
  },
}
