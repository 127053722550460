import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { MetaDataFilterType } from '../../../../../types/MetaDataTypes'
import S from './MetaDataSearch.module.scss'
import dayjs from 'dayjs'
import DatePicker from 'react-datepicker'
import { AuthorizationTokenAtom } from '../../../../../recoil/Authorization.recoil'
import { useRecoilValue } from 'recoil'
import { MetaAdAccountType } from '../../../../../types/LoginTypes'

interface MetaIdNameType {
  id: string
  name: string
}

interface MetaDataSearchProps {
  metaAccounts: MetaAdAccountType[];
  handleSearch: (filter: MetaDataFilterType) => void;
  filters: MetaDataFilterType;
  setFilters: Dispatch<SetStateAction<MetaDataFilterType>>;
  searchType: 'SETS' | 'AD'
}

const MetaDataSearch = (props: MetaDataSearchProps) => {
  const token = useRecoilValue(AuthorizationTokenAtom)
  const { metaAccounts, setFilters, filters, handleSearch, searchType } = props
  const [accounts, setAccounts] = useState<MetaIdNameType[]>([]) // 계정 ID 목록
  const [campaigns, setCampaigns] = useState<MetaIdNameType[]>([])
  const [startDate, setStartDate] = useState<Date>(dayjs().subtract(1, 'day').toDate())
  const [endDate, setEndDate] = useState<Date>(dayjs().toDate())

  // 필수 필드의 유효성 상태를 관리합니다.
  const [isAccountIdValid, setIsAccountIdValid] = useState<boolean>(true)
  const [isCampaignIdValid, setIsCampaignIdValid] = useState<boolean>(true)
  const [isStartDateValid, setIsStartDateValid] = useState<boolean>(true)
  const [isEndDateValid, setIsEndDateValid] = useState<boolean>(true)
  const [isSpendValid, setIsSpendValid] = useState<boolean>(true);
  const [isRoasValid, setIsRoasValid] = useState<boolean>(true);

  const [selectedSpendCondition, setSelectedSpendCondition] = useState<string | undefined>()
  const [selectedRoasCondition, setSelectedRoasCondition] = useState<string | undefined>()
  const [selectedSetsStatus, setSelectedSetsStatus] = useState<string | undefined>();
  const [selectedAdStatus, setSelectedAdStatus] = useState<string | undefined>();

  useEffect(() => {
    setAccounts(metaAccounts.map((account) =>
      ({ id: account.metaAccountId, name: account.metaAccountName })))
    setCampaigns(metaAccounts[0].campaigns.map((campaign) =>
      ({ id: campaign.campaignId, name: campaign.campaignName })))
    setFilters(prev => ({
      ...prev,
      setId: '',
      setName: '',
      adId: '',
      adName: '',
      startDate: dayjs(startDate).subtract(1).format('YYYY-MM-DD'),
      endDate: dayjs(endDate).format('YYYY-MM-DD'),
    }))
  }, [])

  useEffect(() => {
    if (filters.metaAccountId) {
      const selectedAccount = metaAccounts.find(it => it.metaAccountId === filters.metaAccountId)
      if (selectedAccount !== undefined) {
        setCampaigns(selectedAccount?.campaigns.map((campaign) =>
          ({ id: campaign.campaignId, name: campaign.campaignName })))

        setFilters(prev => ({
          ...prev,
          setId: '',
          setName: '',
          adId: '',
          adName: '',
          startDate: dayjs(startDate).format('YYYY-MM-DD'),
          endDate: dayjs(endDate).format('YYYY-MM-DD'),
        }))
      }
    }
  }, [filters.metaAccountId])

  useEffect(() => {
    setStartDate(dayjs(filters.startDate).toDate())
    setEndDate(dayjs(filters.endDate).toDate())
  }, [filters.startDate, filters.endDate])

  const validateFields = () => {
    const isValidAccountId = !!filters.metaAccountId;
    const isValidCampaignId = !!filters.campaignId;
    const isValidStartDate = !!filters.startDate;
    const isValidEndDate = !!filters.endDate;

    // Spend와 ROAS의 유효성 검사
    const isValidSpend = !!(selectedSpendCondition || (!selectedSpendCondition && filters.goeSpend === undefined && filters.loeSpend === undefined));
    const isValidRoas = !!(selectedRoasCondition || (!selectedRoasCondition && filters.goeRoas === undefined && filters.loeRoas === undefined));

    setIsAccountIdValid(isValidAccountId);
    setIsCampaignIdValid(isValidCampaignId);
    setIsStartDateValid(isValidStartDate);
    setIsEndDateValid(isValidEndDate);
    setIsSpendValid(isValidSpend);
    setIsRoasValid(isValidRoas);

    return isValidAccountId && isValidCampaignId && isValidStartDate && isValidEndDate && isValidSpend && isValidRoas;
  };


  const handleSearchClick = () => {
    if (validateFields()) {
      const startDate = dayjs(filters.startDate)
      const endDate = dayjs(filters.endDate)
      const diffDays = endDate.diff(startDate, 'day')

      if (diffDays > 7) {
        alert('7일 이상의 기간은 검색할 수 없습니다. 날짜를 다시 선택해주세요.')
        return
      }

      handleSearch(filters)
    }
  }

  const handleSetsStatusChange = (status: string) => {
    if (selectedSetsStatus === status) {
      setSelectedSetsStatus(undefined);
      setFilters(prev => ({ ...prev, setStatus: undefined }));
    } else {
      setSelectedSetsStatus(status);
      setFilters(prev => ({ ...prev, setStatus: status }));
    }
  };

  const handleAdStatusChange = (status: string) => {
    if (selectedAdStatus === status) {
      setSelectedAdStatus(undefined);
      setFilters(prev => ({ ...prev, adStatus: undefined }));
    } else {
      setSelectedAdStatus(status);
      setFilters(prev => ({ ...prev, adStatus: status }));
    }
  };

  const handleSpendConditionChange = (condition: string) => {
    if (selectedSpendCondition === condition) {
      setSelectedSpendCondition(undefined);
      setFilters(prev => ({ ...prev, goeSpend: undefined, loeSpend: undefined }));
    } else {
      setSelectedSpendCondition(condition);
      // 기존 필터의 값에 따라 상태 업데이트
      if (condition === '>=') {
        setFilters(prev => ({ ...prev, loeSpend: undefined }));
      } else if (condition === '<=') {
        setFilters(prev => ({ ...prev, goeSpend: undefined }));
      }
    }
  };

  const handleRoasConditionChange = (condition: string) => {
    if (selectedRoasCondition === condition) {
      setSelectedRoasCondition(undefined);
      setFilters(prev => ({ ...prev, goeRoas: undefined, loeRoas: undefined }));
    } else {
      setSelectedRoasCondition(condition);
      // 기존 필터의 값에 따라 상태 업데이트
      if (condition === '>=') {
        setFilters(prev => ({ ...prev, loeRoas: undefined }));
      } else if (condition === '<=') {
        setFilters(prev => ({ ...prev, goeRoas: undefined }));
      }
    }
  };

  const handleSpendChange = (value: string) => {
    const numericValue = parseFloat(value);

    if (!selectedSpendCondition) {
      // 수식이 선택되지 않았을 때 경고 메시지 표시
      setIsSpendValid(false);
      // 1초 후에 경고 메시지 자동으로 사라지게 설정
      setTimeout(() => {
        setIsSpendValid(true);
      }, 1000);
    } else if (selectedSpendCondition === '>=') {
      setFilters(prev => ({ ...prev, goeSpend: isNaN(numericValue) ? undefined : numericValue }));
      setIsSpendValid(true);
    } else if (selectedSpendCondition === '<=') {
      setFilters(prev => ({ ...prev, loeSpend: isNaN(numericValue) ? undefined : numericValue }));
      setIsSpendValid(true);
    }
  };

  const handleRoasChange = (value: string) => {
    const numericValue = parseFloat(value);

    if (!selectedRoasCondition) {
      // 수식이 선택되지 않았을 때 경고 메시지 표시
      setIsRoasValid(false);
      // 1초 후에 경고 메시지 자동으로 사라지게 설정
      setTimeout(() => {
        setIsRoasValid(true);
      }, 1000);
    } else if (selectedRoasCondition === '>=') {
      setFilters(prev => ({ ...prev, goeRoas: isNaN(numericValue) ? undefined : numericValue }));
      setIsRoasValid(true);
    } else if (selectedRoasCondition === '<=') {
      setFilters(prev => ({ ...prev, loeRoas: isNaN(numericValue) ? undefined : numericValue }));
      setIsRoasValid(true);
    }
  };


  return (
    <div className={S.searchContainer}>
      <h2>Search Filters</h2>
      <div className={S.searchGroup}>
        <div className={S.flexContainer}>
          <div className={`${S.searchItem} ${S.largeSelect} ${!isAccountIdValid && S.invalidField}`}>
            <label htmlFor="accountId">Account</label>
            <select
              id="accountId"
              value={filters.metaAccountId}
              onChange={(e) => setFilters(prev => ({ ...prev, metaAccountId: e.target.value }))}
            >
              {accounts.map((account) => (
                <option key={account.id} value={account.id}>{account.name}</option>
              ))}
            </select>
            {!isAccountIdValid && <small className={S.errorText}>Account ID is required</small>}
          </div>
          <div className={`${S.searchItem} ${S.largeSelect} ${!isCampaignIdValid && S.invalidField}`}>
            <label htmlFor="campaignId">Campaign</label>
            <select
              id="campaignId"
              value={filters?.campaignId}
              onChange={(e) => setFilters(prev => ({ ...prev, campaignId: e.target.value }))}
            >
              {campaigns.map((campaign) => (
                <option key={campaign.id} value={campaign.id}>{campaign.name}</option>
              ))}
            </select>
            {!isCampaignIdValid && <small className={S.errorText}>Campaign ID is required</small>}
          </div>
        </div>
      </div>

      <div className={S.searchGroup}>
        <div className={S.flexContainer}>
          <div className={`${S.searchItem} ${S.smallSelect}`}>
            <label htmlFor="setsStatus">Sets Status</label>
            <div className={S.inlineGroup}>
              <button
                className={`${S.conditionButton} ${selectedSetsStatus === 'ACTIVE' ? S.active : ''}`}
                onClick={() => handleSetsStatusChange('ACTIVE')}
              >
                ON
              </button>
              <button
                className={`${S.conditionButton} ${selectedSetsStatus === 'PAUSED' ? S.active : ''}`}
                onClick={() => handleSetsStatusChange('PAUSED')}
              >
                OFF
              </button>
            </div>
          </div>
          <div className={S.metaName}>
            <label htmlFor="setName">Sets</label>
            <input
              className={S.searchInput}
              type="text"
              value={filters?.setName}
              onChange={(e) => setFilters(prev => ({ ...prev, setName: e.target.value }))}
              placeholder="Sets 명"
            />
          </div>
          <div className={S.searchItem}>
            <label htmlFor="spend">Spend</label>
            <div className={S.inlineGroup}>
              <button
                className={`${S.conditionButton} ${selectedSpendCondition === '>=' ? S.active : ''}`}
                onClick={() => handleSpendConditionChange('>=')}
              >
                ≥
              </button>
              <button
                className={`${S.conditionButton} ${selectedSpendCondition === '<=' ? S.active : ''}`}
                onClick={() => handleSpendConditionChange('<=')}
              >
                ≤
              </button>
              <input
                type="number"
                value={filters.goeSpend !== undefined ? filters.goeSpend : filters.loeSpend !== undefined ? filters.loeSpend : ''}
                onChange={(e) => handleSpendChange(e.target.value)}
                className={`${S.searchInput} ${!isSpendValid && S.invalidField}`}
              />
            </div>
            {!isSpendValid && !selectedSpendCondition && <small className={S.errorText}>수식 선택 후 입력해 주세요.</small>}
          </div>
        </div>
      </div>

      {searchType === 'AD' && (
        <div className={S.searchGroup}>
          <div className={S.flexContainer}>
            <div className={`${S.searchItem} ${S.smallSelect}`}>
              <label htmlFor="adStatus">Ad Status</label>
              <div className={S.inlineGroup}>
                <button
                  className={`${S.conditionButton} ${selectedAdStatus === 'ACTIVE' ? S.active : ''}`}
                  onClick={() => handleAdStatusChange('ACTIVE')}
                >
                  ON
                </button>
                <button
                  className={`${S.conditionButton} ${selectedAdStatus === 'PAUSED' ? S.active : ''}`}
                  onClick={() => handleAdStatusChange('PAUSED')}
                >
                  OFF
                </button>
              </div>
            </div>
            <div className={S.metaName}>
              <label htmlFor="adName">Ad</label>
              <input
                className={S.searchInput}
                type="text"
                value={filters?.adName}
                onChange={(e) => setFilters(prev => ({ ...prev, adName: e.target.value }))}
                placeholder="광고 명"
              />
            </div>
            <div className={S.searchItem}>
              <label htmlFor="roas">ROAS</label>
              <div className={S.inlineGroup}>
                <button
                  className={`${S.conditionButton} ${selectedRoasCondition === '>=' ? S.active : ''}`}
                  onClick={() => handleRoasConditionChange('>=')}
                >
                  ≥
                </button>
                <button
                  className={`${S.conditionButton} ${selectedRoasCondition === '<=' ? S.active : ''}`}
                  onClick={() => handleRoasConditionChange('<=')}
                >
                  ≤
                </button>
                <input
                  type="number"
                  value={filters.goeRoas !== undefined ? filters.goeRoas : filters.loeRoas !== undefined ? filters.loeRoas : ''}
                  onChange={(e) => handleRoasChange(e.target.value)}
                  className={`${S.searchInput} ${!isRoasValid && S.invalidField}`}
                />
              </div>
              {!isRoasValid && !selectedRoasCondition && <small className={S.errorText}>수식 선택 후 입력해 주세요.</small>}
            </div>
          </div>
        </div>
      )}
      <div className={S.filters}>
        <div className={S.datePicker}>
          <div className={S.datePickerItem}>
            <label htmlFor="from">From</label>
            <DatePicker
              selected={startDate}
              onChange={(date) => date && setFilters(prev => ({
                ...prev,
                startDate: dayjs(date).format('YYYY-MM-DD'),
              }))}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              placeholderText="From"
              dateFormat={'yyyy-MM-dd'}
              className={S.customDatePicker}
            />
          </div>
          <span className={S.datePickerSeparator}>~</span>
          <div className={S.datePickerItem}>
            <label htmlFor="to">To</label>
            <DatePicker
              selected={endDate}
              onChange={(date) => date && setFilters(prev => ({ ...prev, endDate: dayjs(date).format('YYYY-MM-DD') }))}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
              placeholderText="To"
              dateFormat={'yyyy-MM-dd'}
              className={S.customDatePicker}
            />
          </div>
        </div>
        <button className={S.searchBtn} onClick={() => handleSearchClick()}>검색</button>
      </div>
    </div>
  )
}

export default MetaDataSearch