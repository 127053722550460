import {atom} from "recoil";
import { MyInfoType } from '../types/LoginTypes'

export const AuthorizationTokenAtom = atom<string>({
    key: 'awake.authorization.token',
    default: ''
})

export const MyInfoAtom = atom<MyInfoType>({
    key: 'awake.my.info',
    default: {} as MyInfoType
})

export const LoginFailureFlagAtom = atom<boolean>({
    key: 'awake.login.failed',
    default: false
})

