import React, { useState, useEffect } from 'react'
import S from './SetsCreativePopup.module.scss'
import {
  fetchMetaSetsDetails,
  fetchSetsHistories,
} from '../../../../../repository/MetaDataRepository'
import { useRecoilState, useRecoilValue } from 'recoil'
import { AuthorizationTokenAtom } from '../../../../../recoil/Authorization.recoil'
import {
  AdHistoryType,
  MetaAdInsightType,
  MetaSetsDetailType,
} from '../../../../../types/MetaDataTypes'
import _ from 'lodash'
import { MetaAccountsAtom } from '../../../../../recoil/Data.recoil'
import dayjs from 'dayjs'

interface SetsCreativePopupProps {
  setId: string
  from: string
  to: string
  onClose: (type: 'SETS' | 'AD') => void
}

const SetsCreativePopup: React.FC<SetsCreativePopupProps> = ({
  setId,
  from,
  to,
  onClose,
}) => {
  const token = useRecoilValue(AuthorizationTokenAtom)
  const [setsDetail, setSetsDetail] = useState<MetaSetsDetailType | null>(null)
  const [selectedAd, setSelectedAd] = useState<string | null>(null)
  const [setsHistories, setSetsHistories] = useState<AdHistoryType[]>([])
  const [metaAccounts, setMetaAccounts] = useRecoilState(MetaAccountsAtom)

  useEffect(() => {
    document.body.style.overflow = 'hidden'
    fetchMetaSetsDetails(token, setId, from, to).then((data) => {
      setSetsDetail(data)
    })

    fetchSetsHistories(token, metaAccounts[0].metaAccountId, setId).then(
      (data) => {
        const sortedHistories = data.content.sort(
          (a, b) =>
            new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime(),
        )
        setSetsHistories(sortedHistories)
      },
    )
    return () => {
      document.body.style.overflow = 'unset'
    }
  }, [setId])

  if (!setsDetail) {
    return <div>Loading...</div>
  }

  if (!setsDetail) {
    return (
      <div className={S.popupOverlay}>
        <div className={S.popupContent}>
          <div className={S.popupHeader}>
            <h2>Set Details</h2>
            <button className={S.closeButton} onClick={() => onClose('AD')}>
              X
            </button>
          </div>
          <div>
            {!setsDetail ? 'Loading...' : '소재를 불러오는데 실패했습니다.'}
          </div>
        </div>
      </div>
    )
  }

  const renderSetsHistoryTable = () => (
    <table className={S.dataTable}>
      <thead>
        <tr>
          <th>날짜</th>
          <th>제어 유형</th>
          <th>User</th>
          <th>Memo</th>
        </tr>
      </thead>
      <tbody>
        {setsHistories.map((history) => (
          <tr key={history.updatedAt}>
            <td>{dayjs(history.updatedAt).format('YYYY.MM.DD HH:mm')}</td>
            <td>{history.description}</td>
            <td>{history.userEmail}</td>
            <td>{history.memo}</td>
          </tr>
        ))}
      </tbody>
    </table>
  )

  const renderSetsDataTable = () => (
    <table className={S.dataTable}>
      <thead>
        <tr>
          <th>Date</th>
          <th>Name</th>
          <th>Spend</th>
          <th>ROAS</th>
          <th>Revenue</th>
        </tr>
      </thead>
      <tbody>
        {setsDetail.insights.map((insight) => (
          <tr key={insight.date}>
            <td>{insight.date}</td>
            <td>{setsDetail.name}</td>
            <td>{insight.spend?.toLocaleString()}</td>
            <td>{insight.roas?.toLocaleString()}</td>
            <td>{insight.revenue?.toLocaleString()}</td>
          </tr>
        ))}
      </tbody>
    </table>
  )

  const renderAdDetail = () => {
    if (!selectedAd) return null

    const selectedAdDetail = setsDetail.ads.find((ad) => ad.adId === selectedAd)
    const matchingAdInsights = setsDetail.insights
      .flatMap((insight) => insight.adInsights)
      .filter((adInsight: MetaAdInsightType) => adInsight.adId === selectedAd)

    const fields = [
      'date',
      'name',
      'spend',
      'impressions',
      'ctr',
      'cpc',
      'cpm',
      'roas',
    ]

    if (!selectedAdDetail || matchingAdInsights.length === 0) return null

    return (
      <>
        <h4 className={S.detailTitle}>
          {selectedAdDetail.name} 소재 및 Insights
        </h4>
        <div className={S.adDetailSection}>
          {/* 왼쪽: 광고 소재 정보 */}
          <div className={S.materialSection}>
            {selectedAdDetail.adObject && (
              <>
                <h4>{selectedAdDetail.adObject.title}</h4>
                <p className={S.adBody}>{selectedAdDetail.adObject.body}</p>
                {selectedAdDetail.adObject.adLink && (
                  <a
                    href={selectedAdDetail.adObject.adLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={S.adLink}
                  >
                    광고 링크로 이동
                  </a>
                )}
                {selectedAdDetail.adObject.imageUrl ? (
                  <img
                    src={selectedAdDetail.adObject.imageUrl}
                    alt={selectedAdDetail.adObject.title}
                    className={S.adImage}
                  />
                ) : (
                  <div>이미지를 불러올 수 없습니다.</div>
                )}
              </>
            )}
          </div>

          {/* 오른쪽: 광고 성과 테이블 */}
          <div className={S.insightSection}>
            <h3>Insights</h3>
            <div className={S.insightTableWrapper}>
              <table className={S.insightTable}>
                <thead>
                  <tr>
                    {fields.map((field) => (
                      <th key={field}>{field}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {matchingAdInsights.map((adInsight, index) => (
                    <tr key={index}>
                      <td>{adInsight.date}</td>
                      <td>{selectedAdDetail.name}</td>
                      <td>{adInsight.spend?.toLocaleString()}</td>
                      <td>{adInsight.impressions?.toLocaleString()}</td>
                      <td>{adInsight.ctr?.toLocaleString()}</td>
                      <td>{adInsight.cpc?.toLocaleString()}</td>
                      <td>{adInsight.cpm?.toLocaleString()}</td>
                      <td>{adInsight.roas?.toLocaleString()}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </>
    )
  }

  // 상단 오른쪽 광고 리스트 처리
  const renderAdsList = () => (
    <div className={S.adsListWrapper}>
      <table className={S.dataTable}>
        <thead>
          <tr>
            <th>Name</th>
          </tr>
        </thead>
        <tbody>
          {setsDetail.ads.map((ad) => {
            const hasInsights = setsDetail.insights
              .flatMap((insight) => insight.adInsights)
              .some((adInsight) => adInsight.adId === ad.adId)
            return (
              <tr
                key={ad.adId}
                onClick={
                  hasInsights
                    ? () => {
                        if (_.isEmpty(selectedAd)) {
                          setSelectedAd(ad.adId)
                        } else {
                          setSelectedAd(null)
                        }
                      }
                    : undefined
                }
                className={`${selectedAd === ad.adId ? S.selectedRow : ''} ${!hasInsights ? S.unselectable : ''}`}
              >
                <td>{ad.name}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )

  return (
    <div className={S.popupOverlay}>
      <div className={S.popupContent}>
        <div className={S.popupHeader}>
          <button className={S.closeButton} onClick={() => onClose('SETS')}>
            X
          </button>
          <h2>Set Details</h2>
        </div>

        <div className={S.contentWrapper}>
          {/* Sets 제어 히스토리 영역 */}
          {setsHistories.length > 0 && (
            <div className={S.leftSection}>
              <h3>Sets 제어 히스토리</h3>
              {renderSetsHistoryTable()}
            </div>
          )}

          <div className={S.leftSection}>
            <h3>최근 7일 Sets data Insights</h3>
            {renderSetsDataTable()}
          </div>

          <div className={S.rightSection}>
            <h3>{setsDetail.name} 하위 ad list</h3>
            {renderAdsList()}
          </div>
        </div>

        {selectedAd && (
          <div className={S.adDetailWrapper}>{renderAdDetail()}</div>
        )}
      </div>
    </div>
  )
}

export default SetsCreativePopup
