import React, { useEffect } from 'react';
import styles from './Terms.module.scss';
import { useNavigate } from 'react-router-dom';

interface TermsProps {
  closePopup: () => void;
}

const Terms: React.FC<TermsProps> = ({ closePopup }) => {

  useEffect(() => {
    document.body.style.overflow = 'hidden'; // 팝업이 열렸을 때 스크롤 비활성화
    return () => {
      document.body.style.overflow = 'unset'; // 팝업이 닫혔을 때 스크롤 재활성화
    };
  }, []);

  return (
    <div className={styles.popupOverlay}>
      <div className={styles.popupContent}>
        <div className={styles.popupHeader}>
          <h2>이용약관</h2>
          <button className={styles.closeButton} onClick={closePopup}>X</button>
        </div>
        <div className={styles.popupScroll}>
          <p>
            <strong>서비스이용약관</strong>
          </p>
          <p>
            <strong>제1조 목적</strong><br />
            본 약관은 (주)애드커넥션(이하 “회사”라 함)이 고객에게 제공하는 AWAKER (어웨이커) 솔루션의 제공에 관한 고객과 회사의 권리, 의무 및 책임사항, 기타 필요한 사항을 명확하게 규정함을
            목적으로 합니다.
          </p>
          <p>
            <strong>제2조 용어의 정의</strong><br />
            본 약관에서 사용하는 용어의 정의는 아래와 같습니다.
            “서비스”란 AWAKER (어웨이커) 솔루션을 포함하여 회사가 제공하는 광고 운영에 관한 상품 또는 컨텐츠 서비스 및 이에 부수되는 제반 서비스를 말합니다.
            “이용자”란 본 약관에 따라 회사와 서비스 이용 계약을 체결하고 회사가 제공하는 서비스를 이용하는 개인 또는 기업을 의미합니다.
            “관리자”란 이용자의 소속 직원으로 이용자를 대표하여 구성원의 서비스 이용에 대하여 승인을 하거나 권한을 부여하며, 구성원의 서비스 이용에 대한 관리 권한 및 책임이 있는 자를 의미합니다.
            “구성원”이란 관리자에 의해 이용자의 일원으로 승인 받아 관리자가 허용하는 범위 내에서 서비스를 이용하는 자를 의미합니다.
            본 약관에서 사용하는 용어의 정의는 본 조 제1항에서 정하는 것을 제외하고는 관계법령 및 서비스별 정책에서 정하는 바에 의하여, 이에 정하지 아니한 것은 일반적인 상관례에 따릅니다.
          </p>
          <p>
            <strong>제3조 약관의 게시와 개정</strong><br />
            회사는 본 약관의 내용을 이용자가 쉽게 알 수 있도록 회사의 웹페이지 초기 화면에 게시합니다. 다만, 약관의 내용은 연결화면을 통하여 볼 수 있도록 할 수 있습니다.
            회사는 약관의 규제에 관한 법률, 정보통신망 이용촉진 및 정보보호 등에 관한 법률, 전자상거래 등에서의 소비자 보호에 관한 법률 등 관련 법령을 위배하지 않는 범위에서 이 약관을 개정할 수
            있습니다.
            회사는 회사의 웹사이트 내 개정 약관의 게시 등 합리적인 방법을 통해 약관을 개정할 수 있습니다. 회사가 약관을 개정할 경우에는 효력발생일 및 개정 사유를 포함한 자세한 내용을 명시하여 현행
            약관과 함께 제1항의 방식에 따라 개정약관의 효력이 발생하기 최소 7일 전에 공지합니다. 다만, 이용자의 권리 또는 의무에 관한 중요한 사항의 변경 또는 이용자에게 불리한 약관의 변경은 최소한
            30일 전에 공지하고 이용자가 사전에 등록한 메일, 로그인 시 동의 화면 등의 전자적 수단을 통해 명확히 통지하도록 합니다.
            회사가 본 제3항에 따라 개정약관을 공지 또는 통지하면서 이용자에게 약관 변경 적용일까지 거부 의사를 표시하지 않으면 동의한 것으로 본다는 뜻을 명확하게 공지 또는 통지하였음에도 이용자가
            명시적으로 거부의 의사 표시를 하지 아니한 경우 또는 개정약관의 적용일자 이후에 특별한 의사표시 없이 서비스를 이용한 경우 이용자가 개정약관에 동의한 것으로 봅니다.
            회사는 개정약관 적용에 동의하지 않는 이용자에 대해서는 기존 약관을 적용하되, 기존 약관을 적용할 수 없는 특별한 사정이 있는 경우에는 서비스 이용 계약을 해지할 수 있습니다.
            본 약관은 이용자가 약관의 내용에 동의함으로써 효력이 발생하여 서비스 이용 계약 종료일까지 적용됩니다. 단, 채권 또는 채무 관계가 남아있을 경우에는 채권, 채무의 완료일까지 적용됩니다.
            회사와 이용자가 서비스 이용에 관하여 별도 계약을 체결한 경우, 해당 계약이 본 약관에 우선하여 적용됩니다.
          </p>
          <p>
            <strong>제4조 서비스 이용 계약의 성립</strong><br />
            서비스 이용 계약은 이용자가 약관의 내용에 대하여 동의를 한 다음 회사가 제시하는 양식과 절차에 따라 이용 신청을 하고 회사가 이러한 신청에 대하여 승낙함으로써 체결됩니다.
            회사는 이용자의 서비스 이용 신청에 대하여 서비스 이용 승낙을 하는 것을 원칙으로 합니다. 다만, 회사는 다음 각 호에 해당하는 신청에 대하여는 승낙을 하지 않거나 사후에 서비스 이용 계약을
            해지할 수 있습니다.
            이용자가 본 약관에 의하여 이전에 서비스 이용 자격을 상실한 적이 있는 경우, 단 회사의 이용자 재가입 승낙을 얻은 경우에는 예외로 함
            실명이 아니거나 타인의 명의를 이용한 경우
            허위의 정보를 기재하거나, 회사가 요구하는 내용을 기재하지 않은 경우
            만 14세 미만의 신청자인 경우
            이용자가 서비스의 정상적인 제공을 저해하거나 다른 이용자의 서비스 이용에 지장을 줄 것으로 예상되는 경우
            이용자의 귀책 사유로 인하여 승인이 불가능하거나 신청이 회사의 정책에 위반되는 경우
            신청자가 법률 위반 또는 불법 행위 등 부정한 용도로 서비스를 이용하려 하거나 명백하게 사회질서 및 미풍양속에 반할 우려가 있는 경우
            회사로부터 계약해지를 당한 이후 1년이 경과하지 않은 경우
            서비스 이용요금을 체불하거나 부적절한 서비스 이용 내역이 있는 경우
            그 밖에 상기 각 호에 준하는 사유로서, 회사가 신청인의 서비스 이용 신청을 승낙하는 것이 부적절하다고 판단하는 경우
            회사가 서비스 이용 신청을 거부하거나 연기할 것을 결정한 경우 신청인에게 이를 통지합니다.
            제1항에 따른 신청에 있어 회사는 이용자의 종류에 따라 전문 기관을 통한 실명 확인 및 본인 인증을 요청하거나 증빙자료를 요청할 수 있습니다.
            회사는 서비스 관련 설비의 여유가 없거나, 기술상 또는 업무상 문제가 있는 경우에는 승낙을 유보할 수 있습니다.
            이용자는 서비스 이용을 위해 허위 사실이 없는 진실된 정보를 제공하여야 하며, 회사는 제공하는 서비스 상품에 따라 필요한 경우 이용자에게 추가 정보를 요청할 수 있습니다.
          </p>
          <p>
            <strong>제5조 개인정보 수집</strong><br />
            회사는 서비스를 원활히 운영하기 위하여 웹사이트에 게시된 개인정보보호정책에 따라 적법하고 공정한 수단에 의하여 서비스 이용 계약의 성립 및 이행에 필요한 최소한의 개인정보를 수집하고 보관할 수
            있습니다.
            회사는 개인정보의 수집 시 관련 법규에 따라 개인정보 처리방침에 그 수집범위 및 목적을 사전 고지합니다.
          </p>
          <p>
            <strong>제6조 개인정보의 보호</strong><br />
            회사는 개인정보보호법, 정보통신망 이용촉진 및 정보보호 등에 관한 법률 등 관계 법령이 정하는 바에 따라 이용자의 개인정보를 보호하기 위해 노력합니다. 개인정보의 보호 및 사용에 대해서는 관련법
            및 회사의 개인정보처리방침이 적용됩니다.
            회사는 회원의 귀책사유로 개인정보가 유출되어 발생한 피해에 대하여 책임을 지지 않습니다.
          </p>
          <p>
            <strong>제7조 이용자 계정에 대한 관리</strong><br />
            이용자의 아이디와 비밀번호에 대한 관리책임은 이용자에게 있으며 이를 제3자가 이용하도록 하여서는 안 됩니다. 이용자의 고의 또는 관리소홀 등의 과실로 인하여 아이디 또는 비밀번호가 유출되어
            발생한 제3자의 도용, 부정사용 등에 대해서는 회사가 책임을 지지 않습니다.
            이용자는 아이디 및 비밀번호가 도용되거나 제3자가 사용하고 있음을 인지한 경우에는 이를 즉시 회사에 통지하고 회사의 안내에 따라야 하며, 회사에 제3자 도용, 부정사용 사실을 통지하지 않거나
            통지한 경우라도 회사의 안내에 따르지 않아 발생한 불이익에 대해서 회사는 책임을 지지 않습니다.
            회사는 이용자의 아이디가 개인정보 유출 우려가 있거나 사회질서 또는 미풍양속에 어긋나거나 회사 또는 운영자로 오인할 우려가 있는 경우 해당 아이디의 사용을 제한할 수 있습니다.
          </p>
          <p>
            <strong>제8조 이용자 정보의 변경</strong><br />
            이용자는 설정 화면을 통해 언제든지 이용자 정보를 열람하고 수정할 수 있습니다. 다만, 서비스 관리를 위해 필요한 아이디, 비밀번호, 기타 관리자 계정에 관한 정보는 회사의 별도 승낙이 없는 한
            수정이 불가능합니다.
            이용자는 관리자 계정에 관한 정보 등을 변경하려는 경우 유선 연락 및 이메일을 통해 변경사항을 고지하여야 하며, 변경사항 미수정 또는 변경사항 미통지로 인하여 발생한 이용자의 불이익에 대해서는
            회사가 책임을 지지 않습니다.
          </p>
          <p>
            <strong>제9조 이용자에 대한 통지</strong><br />
            회사가 이용자에 대한 통지를 하는 경우 이 약관에 별도 규정이 없는 한 이용자가 회사에 제공한 이메일 주소, 전화 등으로 할 수 있습니다. 이용자가 이메일 주소, 휴대전화번호 정보를 허위로 제출
            또는 등록하거나, 변경된 정보를 수정 또는 회사에 고지하지 않은 경우 회사가 이용자가 사전에 제출 또는 등록한 정보로 발송한 때에 이용자에게 통지가 도달한 것으로 봅니다.
            회사는 이용자 전체에 대한 통지의 경우 서비스 페이지 내 게시판에 7일 이상 게시함으로써 제1항의 통지를 갈음할 수 있습니다.
          </p>
          <p>
            <strong>제10조 서비스의 제공</strong><br />
            회사는 이용자에게 아래와 같은 서비스를 제공하며, 이용자에게 제공하는 서비스를 홈페이지에 게시합니다.
            디지털 마케팅 성과 데이터 수집, 분석 및 리포팅 서비스
            디지털 마케팅 광고의 소재 데이터 수집, 분석 및 리포팅 서비스
            디지털 마케팅 매체의 광고 운영 자동화 서비스
            디지털 마케팅 관련 정보 콘텐츠 제공 서비스
            기타 회원의 광고 성과를 개선하기 위해 회사가 추가 개발하거나 제3자와 제휴 계약 등을 통해 제공하는 일체의 서비스
            회사는 이용자가 개별 구성원에게 부여할 수 있는 계정의 수량을 특정할 수 있으며, 관리자는 부여 받은 계정의 수량 내에서 구성원들에게 개별적으로 계정을 부여합니다. 단, 서비스 개시 시점을
            포함한 서비스 이용 기간은 계정이 할당되지 않은 수량을 포함한 모든 계정에 동일하게 적용됩니다.
            이용자는 특정 구성원이 관리자로부터 승인 받은 계정을 다른 구성원과 공유하지 못하도록 이를 관리, 감독할 책임이 있습니다. 기존 구성원이 퇴사하거나 계정의 권한자를 다른 구성원으로 변경하고자
            하는 경우에는 해당 계정을 재할당할 수 있습니다.
            회사는 이용자가 본 약관을 준수하는 조건으로 이용자 및 구성원에게 서비스를 제공합니다. 단, 설비의 점검 등 회사가 필요한 경우 또는 설비의 장애, 서비스 이용의 폭주 등 불가항력적인 사항으로
            인하여 서비스 이용에 지장이 있는 경우 예외적으로 서비스 이용의 전부 또는 일부를 제한할 수 있습니다.
            회사는 서비스의 제공에 필요한 경우 정기점검을 실시할 수 있으며 정기점검시간은 회사가 사전에 통지한 바에 따릅니다.
            회사는 상담 처리, 데모 서비스 제공이나 기타 회사가 인정하는 경우 서비스의 일부 또는 전부를 일정기간 동안 무료로 제공할 수 있습니다.
            회사는 이용자의 귀책사유로 인한 서비스의 이용 장애에 대하여 책임을 지지 않습니다.
            회사는 사전 통지 없이 서비스의 성능향상 및 서비스 장애의 해결 기타 본 약관상 조건 준수를 보장하기 위해 필요한 목적 범위 내에서 이용자의 서비스 이용을 모니터링 할 수 있습니다. 회사는 긴급
            상황을 예방하고, 이용자의 지원 요청에 대한 응답을 포함하여 서비스를 제공하는 데 필요한 범위 내에서 이용자의 데이터를 사용, 수정, 복제, 배포, 표시 및 공개할 수 있는 권리를 가집니다.
            명확히 하면, 전술한 내용은 회사가 이용자의 서비스 이용을 모니터링할 의무를 발생시키지 않습니다.
            회사는 서비스와 외부 결제대행 업체의 결제 시스템을 연결해 이용자가 서비스 이용요금을 결제할 수 있도록 결제 시스템을 제공할 수 있습니다. 단, 결제대행 업체의 시스템 장애 등 회사에게 책임
            없는 사유로 이용자에게 손해가 발생한 경우 회사는 면책됩니다.
            회사는 더 나은 서비스의 제공을 위하여 무료 체험 서비스 기간 동안 이용자에게 서비스의 이용과 관련된 각종 고지, 관리 메시지 및 기타 광고를 비롯한 다양한 정보를 서비스 화면 내에 표시하거나
            이용자의 이메일로 전송할 수 있습니다.
          </p>
          <p>
            <strong>제11조 서비스의 변경 및 중단</strong><br />
            회사는 서비스의 내용, 품질, 또는 기술적 사양 등에 대해 회사의 합리적 판단에 따라 변경할 수 있습니다. 이 경우에는 변경되는 서비스의 내용 및 적용일자를 명시하여 적용일자로부터 미리 사이트
            초기 화면 등에 공지합니다. 단, 변경 내용이 이용자에게 불리한 변경의 경우에는 적용일자로부터 30일 이전부터 적용일자 전일까지 공지합니다. 회사가 사전에 통지할 수 없는 부득이한 사유가 있는
            경우 사후에 통지할 수 있습니다.
            이용자는 전 항의 변경 내용에 동의하지 않을 경우 서비스 이용 계약의 해지를 요청할 수 있습니다.
            회사는 다음 각 호에 해당하는 경우 서비스의 전부 또는 일부를 제한하거나 중단, 종료할 수 있습니다.
            서비스 제공과 관련된 회사와 제3자 간의 계약의 종료/해지, 서비스용 설비의 보수점검, 교체, 고장, 디도스 공격, IDC장애, 기간 통신사업자의 회선 장애 등과 같은 불가피한 사유가 발생한
            경우
            기타 천재지변, 국가적 비상사태 등으로 정상적인 서비스 이용에 지장이 있는 경우
            서비스 제공과 관련된 제3자의 서비스 또는 시스템(페이스북, 구글, 네이버 등 매체 서비스를 포함하나 이에 한정하지 아니함)의 중단, 장애 등과 같은 불가피한 사유가 발생한 경우
            인프라(서버, 전기, 전기통신, 광대역 인터넷 등을 포함하나 이에 한정하지 아니함.) 서비스 제공업체가 인프라 서비스를 중단한 경우
            회사는 전 항의 사유가 발생하는 경우 이용자에게 사전 통지하며 서비스 접속화면이나 웹사이트상에 게재함으로써 서비스 중단을 공지할 수 있습니다. 단, 회사가 통제할 수 없는 사유로 인한 서비스의
            중단(회사의 고의, 과실이 없는 설비 장애, 시스템 장애 등)으로 인하여 사전 통지가 불가능한 경우에는 그러하지 아니합니다.
            회사는 서비스의 변경, 중단, 종료로 인하여 발생하는 문제에 대하여 어떠한 책임도 지지 않습니다.
          </p>
          <p>
            <strong>제12조 서비스 이용의 정지 또는 종료</strong><br />
            회사는 다음 각 호에 해당하는 경우 사전 통지 없이 이용자의 서비스 이용을 정지할 수 있습니다.
            서비스 이용 신청 또는 변경 시 허위 사실을 기재한 경우
            서비스를 이용하는 이용자가 요금을 납부일까지 납입하지 않은 경우
            안정된 서비스 운용을 저해할 수 있는 다량의 정보 전송, 광고성 정보를 전송 또는 매개하는 행위를 하거나 이러한 행위가 발생하는 경우
            이용자가 서비스를 이용하는 과정에서 서비스의 오작동이나 정보의 파괴, 바이러스 감염 자료의 유포, 비정상 과다 트래픽이 발생하게 하는 등으로 회사의 서비스 운영에 지장 또는 장애가 발생한 경우
            또는 그러한 피해 내지, 지장, 장애가 발생할 위험이 있는 경우
            이용자가 회사의 동의 없이 서비스 또는 이에 포함된 소프트웨어의 일부를 복사, 수정, 배포, 판매, 양도, 대여, 담보제공, 타인의 이용을 허락하거나, 역설계, 소스코드의 추출을 시도하는 등
            복제, 분해, 모방 및 기타의 변형을 시도하는 경우
            이용자가 보안 업데이트를 서버에 적절히 설치하지 않음으로 말미암아 회사의 서비스 운영에 위험요소로 판단되는 경우
            이용자가 국가적 이익이나 공익에 반하는 목적을 위해 서비스를 이용하는 경우
            이용자의 서비스 이용이 관련 법령을 위반하거나 공공 윤리나 질서에 반하는 경우
            이용자의 행위가 타인의 명예를 손상시키거나 불이익을 초래하는 경우
            이용자가 이용 중인 서버가 바이러스에 감염 또는 해킹되었거나 그러한 것으로 의심되는 경우
            서비스를 통해 이용자가 처리하는 이용자의 정보에 대해 제3자가 권리 침해를 주장하는 등 이용자의 서비스 이용을 정지시킬 만한 상당한 이유가 있는 경우
            법적 절차에 따라 정부기관이 서비스의 일시적 이용 정지를 요청 또는 명령하는 경우
            이용자가 회사가 서비스 이용을 위해 정한 조건, 서비스 이용과 관련하여 고지한 제약 또는 제한 사항을 회피하는 방식으로 본건 서비스에 접근하거나 사용하는 경우
            기타 관련 법령이나 회사가 정한 이용약관, 이용조건에 위배되는 경우
            본 조에 따른 사유로 인해 이용자의 서비스 이용을 정지시키는 경우, 회사는 해당 이용자에게 정지 사유, 이용 정지 기간 및 공식적인 이의 제기 수단을 통지합니다. 다만, 회사가 통제할 수 없는
            사유 또는 긴급한 필요가 있는 경우에는 통지 없이 이용을 정지할 수 있습니다.
            서비스 이용 정지 사유가 계속해서 존재하거나 이용자가 정보통신망 이용촉진 및 정보보호 등에 관한 법률 및 컴퓨터프로그램보호법 등 관련 법령을 위반한 경우에는 회사는 즉시 서비스 이용 계약을
            해지할 수 있습니다.
            회사는 이용자가 서비스를 이용하는 경우 본 조에서 기술된 사유로 인해 서비스가 정지된 기간 동안 발생한 요금을 청구할 수 있습니다.
            서비스 이용 계약이 해지되는 경우 회사는 관련 법령에 따라 이용자 정보를 보유해야 하는 경우를 제외하고는 이용자의 데이터를 소멸합니다.
          </p>
          <p>
            <strong>제13조 회사의 의무</strong><br />
            회사는 관련 법령과 이 약관이 금지하거나 미풍양속에 반하는 행위를 하지 않으며, 계속적이고 안정적으로 서비스를 제공하기 위하여 최선을 다하여 노력합니다.
            회사는 서비스 제공과 관련하여 알고 있는 이용자의 개인정보를 이용자의 승낙없이 제3자에게 누설, 배포하지 않습니다. 다만, 관계법령에 의한 관계기관으로부터의 요청 등 법률의 규정에 따른 적법한
            절차에 의한 경우에는 그러하지 않습니다.
            회사는 서비스 제공을 벗어난 목적으로 서비스와 관련한 이용자의 정보에 접근하거나 데이터를 처리하지 않습니다. 다만 장애 처리, 이용자의 정보 보호 등 원활한 서비스 제공을 위하여 접근이 필요한
            경우 이용자의 정보에 접근, 내용을 파악할 수 있습니다.
            회사는 서비스 이용과 관련하여 회원으로부터 제기된 의견이나 불만이 정당하다고 인정할 경우에는 이를 처리하여야 합니다. 다만, 처리에 장기간이 소요되는 경우는 이용자에게 게시판 또는 이메일 등을
            통하여 지체 사유를 안내하고 처리 과정 및 처리 결과를 전달합니다.
          </p>
          <p>
            <strong>제14조 이용자의 의무</strong><br />
            이용자는 구성원이 본 약관의 내용 및 개인정보 보호와 관련하여 이용자가 구성원으로부터 필요한 동의를 받았다는 것을 진술 및 보증합니다.
            이용자는 구성원이 본 약관, 서비스 이용계약, 관계 법령 및 기타의 서비스 이용조건을 준수하도록 하여야 하고, 구성원이 이를 위반한 경우 이용자가 이를 위반한 것으로 간주합니다.
            이용자는 서비스 이용의 대가로 회사가 정한 서비스 이용요금을 지정된 일자를 준수하여 납입하여야 합니다.
            이용자는 다음의 행위를 해서는 안 됩니다.
            서비스 이용 신청 또는 관련 내용 변경 시 허위 내용을 등록하는 행위
            타인의 정보나 명의를 도용하거나 부정하게 사용하는 행위
            자기 또는 타인에게 재산상의 이익을 주거나 타인에게 손해를 가할 목적으로 허위의 정보를 유통시키는 행위
            다른 이용자의 개인정보를 그 동의 없이 수집, 저장, 공개하는 등으로 개인정보를 침해하는 행위
            회사가 게시한 정보를 변경하거나 제3자에게 제공하는 행위
            회사와 기타 제3자의 지적재산권을 포함한 권리를 침해하는 행위
            회사가 제공하는 프로그램 또는 서비스의 일부를 수정, 무단복제하거나 리버스 엔지니어링, 디컴파일, 디스어셈블 및 기타 일체의 가공행위를 통하여 복제, 분해 또는 모방 기타 변형하는 행위
            회사의 동의 없이 본 약관의 목적 범위를 벗어나 영리를 목적으로 서비스를 이용하는 행위
            회사가 제공하는 서비스의 결과물을 불법적인 목적으로 사용하는 행위
            컴퓨터 소프트웨어, 하드웨어, 전기통신 장비의 정상적인 가동을 방해, 파괴할 목적으로 고안된 소프트웨어 바이러스, 악성코드 등 관련 법령에 의하여 전송 또는 게시가 금지되는 정보를 설치, 삽입,
            이용, 또는 전송하는 행위
            기타 웹 해킹 프로그램, 매크로 프로그램, 보이스 피싱을 위한 미러링 사이트 등 타인의 권리를 침해하거나 침해할 우려가 있는 모든 행위
            비인가된 회사 사이트 및 서비스 변경, 해킹, 변경, 훼손, 소스 코드 유출 및 변경하는 행위
            성별, 정치, 종교, 장애, 연령, 사회적 신분, 인종, 지역, 직업 등을 차별하거나 이에 대한 편견을 조장하는 행위
            회사, 다른 이용자 또는 제3자를 차별 또는 비방하거나 명예를 훼손하는 행위
            회사, 다른 이용자 또는 제3자에 대하여 욕설, 폭언, 협박 등을 하는 행위
            회사의 명시적인 동의 없이 이용권한 또는 기타 서비스 이용 계약상 지위를 타인에게 판매, 양도, 대여, 증여, 담보로 제공하거나 타인에게 그 이용을 허락 또는 이를 시도하는 행위 및 이를
            알선하는 행위
            이용자가 다른 이용자, 제3자 또는 회사의 직원을 사칭하는 행위
            기타 관련 법령에서 금지하거나 선량한 풍속 기타 사회통념상 허용되지 아니하거나 원활한 서비스 제공에 악영향을 끼치는 일체의 행위
            이용자는 허용되지 않은 침입자로부터 안전하게 보호받을 수 있도록 시스템 운영과 관련한 정기적인 백신프로그램 등의 보안 업데이트를 하여야 하며, 이를 소홀히 하여 발생한 보안 사고, 손해 등에
            대하여 회사는 책임을 지지 않습니다.
            이용자는 서비스 이용과 관련하여 이메일 내용 등의 각종 데이터 등에 대해 별도로 상시 저장할 의무가 있으며, 이를 소홀히 하여 발생한 정보의 유출, 누락 또는 자료의 손실에 대해 회사는 책임을
            지지 않습니다.
            이용자는 관계법령, 본 약관의 규정, 이용 안내 및 서비스와 관련하여 공지한 주의사항, 회사가 통지하는 사항 등을 준수하여야 하며, 기타 회사의 업무에 방해되는 행위를 하여서는 안 됩니다.
          </p>
          <p>
            <strong>제15조 권리의 귀속</strong><br />
            회사가 개발한 응용 소프트웨어 및 서비스에 대한 저작권 및 지적재산권은 회사에 귀속됩니다.
            서비스 가격에 대한 자기결정권
            서비스 운영에 대한 자율성과 완전성
            서비스에 대한 법적 소유권
            회사는 서비스와 관련하여 이용자에게 회사가 정한 이용 조건에 따른 범위 내에서 이용권을 부여하며, 이용자는 이를 양도, 판매, 담보 제공 등의 처분행위를 할 수 없습니다.
          </p>
          <p>
            <strong>제16조 이용 요금, 결제 및 보상 등</strong><br />
            회사가 제공하는 서비스 이용과 관련하여 이용자가 납부하여야 할 서비스 이용 요금 및 납부 방법은 홈페이지에 게재한 바에 따라 적용되며, 회사가 별도 계약을 체결하는 경우 계약서에 명시된 요금을
            적용합니다.
            서비스 이용 도중 과금 기준이 변경되더라도 이미 결제가 완료된 서비스 이용분에 대하여 증감된 서비스 이용요금을 추가로 청구하거나 환불하지 않습니다.
            회사는 판매 촉진, 이용자 유치 등의 목적을 위해 회사의 정책에 따라 가격 할인, 이용기간 연장 등 프로모션에 따른 혜택을 제공할 수 있습니다. 이용자의 서비스 신청 내용이 회사의 프로모션 제공
            조건에 부합하지 않는 경우 회사는 프로모션에 따른 혜택을 제공하지 않을 수 있으며, 회사는 그에 대한 어떠한 책임도 부담하지 않습니다.
            이용자는 서비스 이용을 위한 서비스 이용 요금 결제 시 회사가 정하는 범위 내에서 결제수단과 결제방식을 선택할 수 있습니다.
            이용자는 서비스 이용 요금을 결제함에 있어 다음의 내용을 유의하여야 합니다.
            이용자 본인 외에 결제수단 사용을 금지합니다.
            이용자는 결제수단의 비밀번호, 인증서 등의 유출로 인한 부정사용 또는 위변조 사고가 발생하지 않도록 주의하여야 합니다. 특히 이용자는 신용카드의 미서명, 관리 소홀, 무단 대여나 양도 또는
            담보제공으로 인한 부정사용, 불법 현금대출, 위변조 사조 등이 발생하지 않도록 주의하여야 합니다.
            회사는 서비스 이용요금 결제가 정상적으로 이루어지지 않은 경우 이용자의 서비스 이용을 제한할 수 있으며, 서비스 이용요금을 미납하는 등 이용자의 귀책 사유로 회사가 서비스 이용을 제한한 경우
            이용자에게 발생한 손해에 대해서 회사는 면책됩니다.
            회사는 회사의 사정에 따라 결제 방식, 결제 수단을 추가, 삭제, 변경할 수 있으며, 사전에 그 사유와 적용일자를 공지합니다.
          </p>
          <p>
            <strong>제17조 계약 해지 등</strong><br />
            이용자는 서비스 이용 계약을 해지하고자 할 때 본인이 직접 서비스를 통하여 신청하거나 이메일, 전화 등의 방법을 통하여 회사에 신청하여야 합니다.
            회사는 전 1항의 규정에 의하여 해지신청이 접수되면 즉시 서비스 이용 계약을 해지합니다. 단, 채권, 채무 관계가 남아있을 경우에는 그러하지 아니합니다.
            회사는 이용자가 다음 각 호에 해당할 경우에는 이용자의 동의 없이 서비스 이용 계약을 해지할 수 있으며 지체없이 그 사실을 이용자에게 통지합니다. 다만 회사가 긴급하게 해지할 필요가 있다고
            인정하는 경우나 이용자의 귀책사유로 인하여 통지할 수 없는 경우에는 사후 통지로 대체할 수 있습니다.
            이용자가 ‘이용자의 의무’를 포함한 본 약관을 위반하고 일정기간 이내에 위반 내용을 해소하지 않은 경우
            회사의 서비스 제공 목적 외의 용도로 서비스를 이용하거나 제3자에게 임의로 서비스를 임대한 경우
            회사의 이용 요금 등의 납입청구에 대하여 이용자가 이용요금을 체납할 경우
          </p>
          <p>
            <strong>제19조 책임 제한</strong><br />
            다른 규정에도 불구하고, 다음에 해당하는 경우 그것이 예측 가능한 상황이라 할지라도 회사는 계약 상이든 불법 행위(과실 포함) 상이든 이와 관련하여 발생하는 어떠한 손실이나 손해에 대하여 법령이
            허용하는 최대한의 범위 내에서 책임을 지지 않습니다.
            천재지변이나 전쟁 등 불가항력에 의한 경우
            이용자의 고의 또는 과실에 의한 경우
            제3자의 서비스 또는 시스템(페이스북, 구글, 네이버 등 매체 서비스를 포함하나 이에 한정하지 아니함)가 원인이 된 경우
            회사, 이용자가 사용하는 인프라 서비스가 원인이 된 경우
            회사가 고의 또는 중과실로 서비스를 중단한 경우를 제외하고, 서비스 진단 등 부득이한 사정으로 인해 사전 공지 후 서비스를 중단한 경우
            회사가 사전 통지 또는 공지된 정기 점검 실행을 위해 서비스를 중단한 경우
            이용자의 이용이 서비스 범위를 초과하는 경우
            이용자가 회사에 제공한 정보의 부정확, 부적법함 등으로 인해 이용자 자신 또는 제3자에게 손해가 발생한 경우
            이용자의 시스템 보안 관리 소홀로 인하여 침해가 발생한 경우
            국가적 비상사태, 전국적 네트워크 장애, 또는 이에 상응하는 불가항력으로 인해 서비스가 중단된 경우
            서비스를 이용하는 이용자의 시스템에서 발생한 사고의 확산을 방지하기 위해 서비스가 중단되는 경우
            회사가 관련 법령에 따른 보호조치를 취하였음에도 외부로부터의 불법적 침해로 인해 서비스 장애가 발생한 경우
            이용자가 서비스 이용과 관련하여 설치하거나 연결한 장비, 소프트웨어, 애플리케이션 또는 OS에서 장애가 발생하는 경우
            이용자가 무료 서비스를 이용하는 경우
            이용자는 서비스가 어떠한 종류의 보증도 없이 결함이 있는 그대로 제공된다는 점, 회사가 조건, 품질, 내구성, 수행능력, 정확성, 신뢰성, 상업성 또는 특별 목적에 대한 적합성, 그리고 침해
            방지, 또는 원활한 서비스, 오류 방지, 유해 요소 방지, 보안, 또는 기능이나 데이터의 손해나 손실 유발 방지 등을 포함하되 이에 한정 짓지 않는 내용에 대하여 암시적으로나 명시적으로 일체의
            보증이나 약속도 하지 않는다는 점을 명시적으로 이해하고 동의합니다.
            회사는 서비스가 제공하는 정보의 유효성, 정확성, 신뢰성, 품질, 안정성, 완전성 또는 현재성을 대표 또는 보장하지 않습니다.
          </p>
          <p>
            <strong>제20조 손해 배상 등</strong><br />
            회사는 이용자가 서비스를 이용함에 있어 회사의 고의 또는 중대한 과실로 인해 손해가 발생한 경우에만 민법 등 관련 법령이 규율하는 범위 내에서 그 손해를 배상합니다.
            이용자가 이 약관을 위반하거나 관계 법령을 위반하여 회사에 손해가 발생한 경우에는 회사에 그 손해를 배상하여야 합니다.
            전항의 경우 손해배상액은 회사의 법률 비용, 경비 등을 포함하되 이에 한정되지 않습니다.
            이용자가 이 약관을 위반하거나 관계 법령을 위반하여 제3자가 회사를 상대로 민형사상의 법적 조치를 취하는 경우에는 이용자는 자신의 비용과 책임으로 회사, 계열사, 각각에 해당하는 임직원,
            대리인, 협력사 및 라이선스 사용허가권자를 면책시켜야 하며, 이들이 피해를 입지 않도록 하는데 동의합니다. 또한, 이로 인해 발생하는 손해에 대해 배상하여야 합니다.
          </p>
          <p>
            <strong>제21조 약관 외 준칙</strong><br />
            이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 관계 법령 또는 일반적인 상관례에 따릅니다.
          </p>
          <p>
            <strong>제22조 준거법 및 관할법원</strong><br />
            본 약관 및 서비스와 관련한 분쟁은 대한민국 법률을 준거법으로 합니다.
            본 약관 및 서비스와 관련한 분쟁은 서울중앙지방법원을 제1심 전속적 관할법원으로 하여 해결합니다.
          </p>
          <p>
            <strong>제23조 분리 규정</strong><br />
            본 약관의 어느 규정이 무효 또는 집행 불가하다고 판단되더라도, 그 나머지 조항들의 유효성과 집행 가능성은 이에 영향을 받지 않고 여전히 유효하며 강제력을 갖습니다.
          </p>
          <p>
            <strong>제24조 권리 불포기</strong><br />
            이용자의 본 약관 위반과 관련하여 회사가 권리 실행이나 조치를 취하지 않았다고 하더라도, 이후 결과적으로 또는 유사하게 발생하는 행위에 대해 회사가 약관의 권리 및 해당 권리 실행을 포기하는
            것으로 간주되지는 않습니다.
          </p>
          <p>
            <strong>부칙 제1조 적용일자</strong><br />
            본 약관은 2024년 9월 1일부터 적용됩니다.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Terms;
