import React, { useEffect, useState } from 'react'
import S from './MyAccount.module.scss';
import { UserInfoService } from '../../../../service/UserInfoService';
import { AuthorizationTokenAtom, MyInfoAtom } from '../../../../recoil/Authorization.recoil';
import { useRecoilValue } from 'recoil';
import { MetaAdAccountType } from '../../../../types/LoginTypes'

const MyAccount = () => {
  UserInfoService();

  const token = useRecoilValue(AuthorizationTokenAtom);
  const myInfo = useRecoilValue(MyInfoAtom);

  const [metaAdAccounts, setMetaAdAccounts] = useState<MetaAdAccountType[]>([]);
  const [isMetaAdAccountsVisible, setMetaAdAccountsVisible] = useState(true);

  useEffect(() => {
    if(myInfo?.adAccount?.metaAdAccounts){
      setMetaAdAccounts(myInfo.adAccount.metaAdAccounts);
    }
  }, [myInfo, token]);

  if (!myInfo.id) {
    return <div>Loading...</div>;
  }

  const toggleMetaAdAccounts = () => {
    setMetaAdAccountsVisible(!isMetaAdAccountsVisible);
  };

  return (
    <div className={S.container}>
      <h1>My Profile</h1>
      <div className={S.userInfo}>
        <div className={S.field}>
          <label>EMAIL ADDRESS</label>
          <input type="text" value={myInfo.email} readOnly />
        </div>
        <div className={S.field}>
          <label>TYPE</label>
          <input type="text" value={myInfo.type} readOnly />
        </div>
      </div>
      <h2>Ad Account</h2>
      <div className={S.adAccount}>
        <div className={S.field}>
          <label>Name</label>
          <input type="text" value={myInfo.adAccount.name} readOnly />
        </div>
        <div className={S.field}>
          <label>Description</label>
          <input type="text" value={myInfo.adAccount.description} readOnly />
        </div>
        <div className={S.field}>
          <label>Tier</label>
          <input type="text" value={myInfo.adAccount.tier} readOnly />
        </div>
        <div className={S.metaAdAccounts}>
          <h3 onClick={toggleMetaAdAccounts} className={S.toggleHeader}>
            Meta Ad Accounts {isMetaAdAccountsVisible ? '▼' : '▲'}
          </h3>
          {isMetaAdAccountsVisible &&
            metaAdAccounts.map((account) => (
              <div
                key={account.metaAccountId}
                className={`${S.metaAdAccount} ${S.field}`}
              >
                <label>Name</label>
                <input type="text" value={account.name} readOnly />
                <label className={S.labelMargin}>Description</label>
                <input type="text" value={account.description} readOnly />
              </div>
            ))}
        </div>
      </div>
    </div>
  )
};

export default MyAccount;
